







































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import CreateCompanyRequestDTO from "@/dto/company/CreateCompanyRequestDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromCompany, fromPerson} from "@/dto/auth/Workspace";
import CustomerAccounts from "@/dto/CustomerAccounts";
import MergeAccountModal from "@/components/profile/admin/MergeAccountModal.vue";
import PhoneInput from "@/components/util/PhoneInput.vue";
import {AccountType} from "@/constants/AccountTypes";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid} from "@/utils/ComponentUtils";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {PortalInput, PhoneInput}
})
export default class MergeCompanyEinModal extends Vue{
    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    @Prop()
    private onSuccess!: () => void

    @Prop()
    private request!: CreateCompanyRequestDTO;

    @Prop()
    private existCompany!: CompanyDTO;


    closeModal(){
        this.$modal.hideAll();
    }

    handleSuccess() {
        this.onSuccess();
        this.stopLoading();
        this.closeModal();
    }

    save() {
        ifValid(this, () => {
			this.startLoading()
			if (this.request.phone != null) {
				if (7  > this.request.phone.length) {
					this.request.phone = null;
				}
			}
			this.message = ""
			this.request.isMerge = true;
			this.request.email = this.request.email?.trim() == '' ? null : this.request.email;
			CompanyService.registerCustomer(this.request).then(
				() => this.handleSuccess(),
				err => {
					this.errorHandling(err)
				}
			)
		})
    }

    errorHandling(err: any) {
        this.successful = false;
        let systemCode = err?.response?.data.systemCode;
        let param = err?.response?.data.param;
        let company: CompanyDTO;
        let customerAccounts: CustomerAccounts;
        this.$modal.hide("hide")
        switch (systemCode) {
            case 40024:
			case 40033:
                company = param;
                if (!(company.email == null && this.request.email != null)) {
                    this.openLinksModal(company);
                }
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), {name: company.name, ein: company.ein, tin: company.nationalTin}) as string;
                break;
            case 40025:
                customerAccounts = param;
                this.openMergeAccountModal(customerAccounts)
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
                    {
                        lastName: customerAccounts.person?.lastName,
                        firstName: customerAccounts.person?.firstName,
                        listCompanyNames: this.handleCompaniesName(customerAccounts.companies)
                    }) as string;
                break
            default:
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
        }
        this.stopLoading();
    }

    handleCompaniesName(companies: Array<CompanyDTO>): string {
        let res = "";
        for (let i = 0; i < companies.length; i++) {
            res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
        }
        return res;
    }

    openLinksModal(company: CompanyDTO) {
        this.$modal.show(
            AdminLinksModal,
            {
                workspace: fromCompany(company),
                name: company.name,
            },
            {
                width: "60%",
                height: "auto",
                scrollable: true,

            }
        );
    }

    openMergeAccountModal(customerAccounts: CustomerAccounts) {
        this.$modal.show(
            MergeAccountModal,
            {
                customerAccounts: customerAccounts,
                companyReq: this.request,
                mode: AccountType.COMPANY,
                onSuccess: () => this.handleSuccess()
            },
            {
                width: "70%",
                height: "auto",
                scrollable: true
            }
        );
    }
}
