
















































import {Component, Prop, Vue} from "vue-property-decorator";
import RouteNames from "../../../router/RouteNames";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import Workspaces from "@/state/Workspaces";

const Auth = namespace("Auth");

@Component
export default class AdminLinksModal extends Vue {

  private routes = RouteNames

  @Prop({default: false})
  private admin!: boolean;

  @Prop()
  private workspace!: Workspace;

  @Prop({default: null})
  private name!: string;

  goToPersonalTaxation() {
    this.changeWorkspaceAndCloseModal();
    this.$router.push({name: this.routes.TAXATION_INDIVIDUAL_CUSTOMER_LIST, params: {userId: `${this.workspace.userId}`}});
  }

  goToProfile() {
    this.changeWorkspaceAndCloseModal();
    this.$router.push({name: this.routes.USER_PROFILE, params: {id: `${this.workspace.id}`, type: this.workspace.type == WorkspaceType.COMPANY ? 'c' : 'p'}});
  }

  goToArchive() {
    this.changeWorkspaceAndCloseModal();
    this.$router.push({name: this.routes.ARCHIVE_AUTH, params: {userId: `${this.workspace.userId}`}});
  }

  goToPayroll() {
    if (this.workspace.type == WorkspaceType.COMPANY) {
      this.$router.push({name: RouteNames.PAYROLL_BUSINESS, params: {companyId: `${this.workspace.id}`}});
    } else {
      this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL, params: {personId: `${this.workspace.id}`}});
    }
    this.changeWorkspaceAndCloseModal();
  }

  goToSalesTax() {
    this.changeWorkspaceAndCloseModal();
    this.$router.push({name: RouteNames.SALES_TAX_DOCUMENTS, params: {companyId: `${this.workspace.id}`}});
  }

  changeWorkspaceAndCloseModal() {
    this.switchWorkspace();
    this.closeModal();
  }

  closeModal() {
    this.$modal.hideAll()
  }

  switchWorkspace(){
    Workspaces.changeWorkspace(this.workspace);
  }


  get isIndividual(){
    return this.workspace.type == WorkspaceType.PERSON;
  }

  get isCompany(){
    return this.workspace.type == WorkspaceType.COMPANY;
  }
}
