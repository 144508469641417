import PersonDTO from "@/dto/person/PersonDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";

export default class CustomerAccounts {
    public email: string | null = null;

    public person: PersonDTO | null = null;

    public companies: Array<CompanyDTO> = [];

    public employee: SublimeEmployeeDTO | null = null;
}